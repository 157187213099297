import { motion } from "framer-motion";
import React from "react";
import ChevronUpIcon from "~/assets/svg/ChevronUp";

function Accordion(props) {
  const [isActive, setIsActive] = React.useState(!!props.isActive);

  React.useEffect(() => {
    setIsActive(!!props.isActive);
  }, [props.isActive]);

  const { title, description, theme = "light" } = props;

  return (
    <div
      className="flex flex-col items-center justify-center self-stretch rounded-2xl border border-base-300 border-opacity-30 bg-base-300 bg-opacity-40 backdrop-blur-lg"
      id={props.fragmentId}
      data-theme={theme}
    >
      <div
        className="flex items-center justify-between gap-4 self-stretch p-5 md:p-6"
        onClick={() => {
          setIsActive(!isActive);
        }}
      >
        <div className="text-sm md:text-base font-semibold leading-normal text-base-content">
          {title}
        </div>
        {isActive ? (
          <ChevronUpIcon
            className="inline h-5 w-5 self-start"
            fill="currentColor"
            fillRule="evenodd"
          />
        ) : (
          <ChevronUpIcon
            className="inline h-5 w-5 rotate-180 self-start"
            fill="currentColor"
            fillRule="evenodd"
          />
        )}
      </div>

      <motion.div
        initial="closed"
        animate={isActive ? "open" : "closed"}
        exit="closed"
        variants={{
          closed: {
            height: 0,
            opacity: 0,
          },
          open: {
            height: "auto",
            opacity: 1,
          },
        }}
        // transition={{ duration: 0.35, ease: "easeInOut" }}
        className="flex justify-start self-stretch rounded-xl bg-base-300 overflow-hidden"
      >
        <div className="flex items-center justify-end self-stretch px-6 py-4">
          <div
            className="text-sm md:text-base font-normal leading-loose text-base-content"
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </div>
      </motion.div>
    </div>
  );
}

export default Accordion;
